.corporate-intro {
  @include padding-top($spacer * 9);
  @include padding-bottom($spacer * 9);

  .lead {
    @include margin-bottom($spacer * 2.25);

    &:after {
      content: " ";
      display: block;
      width: 86px;
      height: 4px;
      background-color: $black;
      @include margin-top($spacer * 1.5);
    }
  }

  .blockquote {
    @include font-size($h2-font-size);
    line-height: 1.2857;
  }
}

@include media-breakpoint-down(sm) {
  .corporate-intro {
    .row {
      margin: 0;
    }
    .row > * {
      padding-left: $spacer * 1.25;
      padding-right: $spacer * 1.25;
    }
  }
}