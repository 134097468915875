.stories {
    h2 {
        @include margin-bottom($spacer * 2.25);
    }
}

@include media-breakpoint-down(sm) {
    .stories {
        padding: 0;
        .row {
            margin: 0;
        }
        .row > * {
            padding-left: $spacer * 1.25;
            padding-right: $spacer * 1.25;
        }
    }
    .story {
        .col {
            padding-left: $spacer * 1.25;
            padding-right: $spacer * 1.25;
        }
    }
}