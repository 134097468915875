.figure {
  display: block;
}

.figure-img {
  margin-bottom: 0;
}

.figure-caption {
  margin-top: $spacer * 0.75;

  @include media-breakpoint-down(md) {
    font-size: $font-size-sm;
  }
}
