h1 {
  font-weight: $font-weight-medium;

  span {
    font-weight: $font-weight-light;
    display: block;
  }
}

h2, h3, h4, h5, h6 {
  font-weight: $font-weight-normal;
}

figure {
  margin-bottom: 0;

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote {
  p:before {
    content: "\201C";
  }

  p:after {
    content: "\201D";
  }
}

.blockquote-footer {
  margin-top: 0;
  margin-bottom: 0;

  &:before {
    content: " ";
    display: block;
    width: 86px;
    height: 4px;
    background-color: $black;
    @include margin-bottom($spacer * 3);
  }
}

ol,
ul,
dl {
  margin-bottom: $paragraph-margin-bottom;
}

.custom-list {
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    margin-bottom: $paragraph-margin-bottom;
    text-indent: 1.4375rem;

    &:before {
      content: "—\00a0";
      position: absolute;
      left: 0;
      font-weight: $font-weight-medium;
      width: 1.4375rem;
      text-indent: 0;
    }
  }
}

ol.custom-list {
  counter-reset: custom-counter;

  li {
    counter-increment: custom-counter;
    text-indent: 2.375rem;

    &:before {
      content: counter(custom-counter) "\00a0—\00a0";
      width: 2.375rem;
    }
  }
}
