.bg-gray-opacity {
  background-color: rgba(134, 146, 154, 0.12);
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;

  @include media-breakpoint-up(xl) {
    padding-top: $spacer * 4.5;
    padding-bottom: $spacer * 4.5;
  }
}

@include media-breakpoint-down(sm) {
  .facts {
    padding: 0;
    .row {
      margin: 0;
    }
    .row > * {
      padding-left: $spacer * 1.25;
      padding-right: $spacer * 1.25;
    }
    .facts-images {
      margin-bottom: $spacer * 3;
    }
  }
}

.facts-images {
  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 2.25;
  }
}

.fact-image {
  position: relative;

  .overlay {
    position: absolute;
    bottom: 0;
    padding: $spacer * 1.875;

    @include media-breakpoint-only(md) {
      padding: $spacer * 1.125;
    }
  }

  p {
    margin-bottom: 0;
  }

  .tag {
    font-size: $font-size-base * 0.7778;
    line-height: normal;
    margin-bottom: $spacer * 0.75;

    @include media-breakpoint-only(md) {
      font-size: $font-size-base * 0.5556;
      margin-bottom: $spacer * 0.375;
    }
  }

  .lead {
    font-size: $font-size-base * 1.2222;
    font-weight: $font-weight-light;
    line-height: normal;

    @include media-breakpoint-only(md) {
      font-size: $font-size-base * 0.8889;
    }
  }
}

.fact.col-md-4 {
  margin-bottom: $spacer * 1.5;
}

.fact.col-md-6:not(:last-child) {
  @include media-breakpoint-down(md) {
    margin-bottom: $spacer * 1.5;
  }
}
