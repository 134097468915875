.navbar-brand {
  padding-left: $spacer;
  padding-right: $spacer;
  margin-left: calc(($spacer + 2px) * -1);
  border: 2px solid transparent;

  svg {
    g {
      fill: $white;
    }

    @include media-breakpoint-down(lg) {
      width: 148px;
    }
  }

  &:focus-visible {
    border: 2px solid $gray;
    outline: 0;
    border-radius: 4px;
  }
}

.navbar-text {
  padding: 0;

  @include media-breakpoint-only(md) {
    font-size: $font-size-base * 0.8889;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: 2px solid transparent;

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    border: 2px solid $gray;
    outline: 0;
    border-radius: 4px;
  }
}

.navbar-toggler-icon {
  width: 32px;
  height: 14px;
}

.navbar {
  @include media-breakpoint-down(sm) {
    .container {
      padding-left: $spacer * 1.25;
      padding-right: $spacer * 1.25;
    }
  }

  padding-top: $spacer * 1.875; // padding without navbar-brand padding
  padding-bottom: $spacer * 0.625; // padding without navbar-brand padding

  @include media-breakpoint-up(md) {
    padding-top: $spacer * 2.25;
    padding-bottom: $spacer * 0.625;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $spacer * 3.375;
    padding-bottom: $spacer * 1.125;
  }

  .btn-download {
    border: 2px solid transparent;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover,
    &:focus-visible {
      color: $white;
    }

    &:focus-visible {
      border: 2px solid $gray;
      outline: 0;
      border-radius: 4px;
    }

    svg {
      width: 30.4px;
      height: 26px;
    }
  }
}

.navbar-scrolled {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: $white;
  border-bottom: 1px solid $black;

  .navbar-brand {
    width: 256px;

    svg {
      width: 148px;
      g {
        fill: $nero;
      }
    }
  }

  .btn-download {
    &:hover {
      color: $gray;
    }

    &:focus-visible {
      color: $black;
    }
  }

  .navbar-toggler {
    &:hover {
      .navbar-toggler-icon {
        background-image: escape-svg($navbar-dark-toggler-icon-hover-bg);
      }
    }
  }
}

.no-header-image {
  .navbar-brand {
    svg {
      g {
        fill: $nero;
      }
    }
  }
}

.navbar-nav {
  .nav-link {
    &.active,
    &.show {
      text-decoration: underline;
    }
  }
}
