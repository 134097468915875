.hero {
  width: 100%;
  color: $white;
  margin-top: auto;
  margin-bottom: $spacer * 5;

  @include media-breakpoint-down(md) {
    &.customers {
      margin-bottom: $spacer;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 6;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer * 6.25;
  }

  @include media-breakpoint-up(xxl) {
    margin-bottom: $spacer * 11.25;
  }

  @include media-breakpoint-down(sm) {
    padding-left: $spacer * 1.25;
    padding-right: $spacer * 1.25;
  }

  h1 {
    margin-bottom: 0;
    font-size: $font-size-base * 2;

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $h1-font-size;
    }

    &:after {
      content: " ";
      display: block;
      width: 86px;
      height: 4px;
      background-color: $white;
      @include margin-top($spacer * 2.25);
    }
  }

  .lead {
    margin-bottom: 0;
    font-size: $font-size-base * 0.8889;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }

    @include media-breakpoint-up(xxl) {
      font-size: $lead-font-size;
    }

    @include media-breakpoint-up(lg) {
      width: 80%;
    }

    @include media-breakpoint-up(xl) {
      width: 66%;
    }
  }

  .category {
    @include font-size($font-size-base * 0.8889);
    text-transform: uppercase;
    line-height: normal;
    @include margin-bottom($spacer * 1.5);
  }

  .headline-container {
    display: flex;

    &:has(+ .lead) {
      @include margin-bottom($spacer * 3);
    }
  }
}

.no-header-image {
  .hero {
    color: $black;
  }
}
