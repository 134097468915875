.tags {
  @include margin-top($spacer * 3);
  @include margin-bottom($spacer * 1.5);

  @include media-breakpoint-down(sm) {
    padding-left: $spacer * 1.25;
    padding-right: $spacer * 1.25;
  }

  .list-inline {
    display: flex;
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  .list-inline-item {
    display: flex;
    margin-bottom: $list-inline-padding;

    &:not(:last-child) {
      @include media-breakpoint-down(sm) {
        margin-right: $list-inline-padding * 0.5;
      }
    }
  }

  img {
    width: 54px;
    height: 54px;
  }

  span {
    @include font-size($font-size-sm);
    line-height: 1.2857;
    display: block;
    margin-left: $spacer * 0.75;
    align-self: center;
    width: 86px;
  }

  & + .text-with-headline {
    margin-top: 0;
  }
}