.btn {
  &:focus-visible {
    box-shadow: none;
  }
}

.btn-read-more {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  gap: $spacer * 0.75;
  padding: 0;
  text-align: left;

  svg {
    width: 54px;
    height: 54px;
    @include padding($spacer);
    border: 1px solid $black;
  }

  &:hover,
  &:focus-visible {

    svg {
      background-color: $black;
      path {
        color: $white;
      }
    }
  }

  &.text-white {
    display: flex;
    flex-direction: column;

    svg {
      border-color: $white;
    }

    &:hover,
    &:focus-visible {

      svg {
        background-color: $white;
        path {
          color: $black;
        }
      }
    }
  }
}

.btn-video {
  border: 2px solid transparent;
  padding: $spacer * 0.25;
  align-self: flex-end;

  svg {
    circle {
      fill: $white;
      fill-opacity: 0.12;
    }
    g {
      stroke: $white;
    }
    path {
      fill: $white;
    }
  }

  &:hover,
  &:focus-visible {
    svg {
      circle {
        fill-opacity: 0.36;
      }
    }
  }

  &:focus-visible {
    border: 2px solid $gray;
    outline: 0;
    border-radius: 50%;
  }
}

.btn-social {
  padding: $spacer * 0.75;
}
