@font-face {
  font-family: 'Helvetica Neue';
  src: url('/2023/fonts/HelveticaNeueW02-55Roman.ttf') format('truetype');
  font-display:swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/2023/fonts/HelveticaNeueW02-45Light.ttf') format('truetype');
  font-display:swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/2023/fonts/HelveticaNeueW02-65Medium.ttf') format('truetype');
  font-display:swap;
  font-weight: 500;
  font-style: normal;
}