/**
 * General spacing between blocks
 */
.text-with-headline,
.text-with-image,
.blockquote-with-image .content-wrapper,
.image-only,
.image-with-headline,
.back-to-overview,
.vimeo {
  @include media-breakpoint-down(sm) {
    padding-left: $spacer * 1.25;
    padding-right: $spacer * 1.25;
  }
}

.text-with-headline,
.text-with-image,
.image-only,
.image-with-headline {
  margin-top: $spacer * 3;
  margin-bottom: $spacer * 3;

  @include media-breakpoint-up(lg) {
    margin-top: $spacer * 4.5;
    margin-bottom: $spacer * 4.5;
  }
}

.text-with-image:has(+ .text-with-headline),
.image-only:has(+ .text-with-headline),
.image-with-headline:has(+ .text-with-headline) {
  margin-bottom: $spacer * 4.5;

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer * 6;
  }
}

.blockquote-with-image,
.back-to-overview,
.vimeo {
  margin-top: $spacer * 4.5;
  margin-bottom: $spacer * 4.5;

  @include media-breakpoint-up(lg) {
    margin-top: $spacer * 6;
    margin-bottom: $spacer * 6;
  }
}

/**
 * Text with headline
 */
.text-with-headline {
  p:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    h2 {
      margin-bottom: 0;
    }

    p, ol, ul {
      padding-left: $spacer * 4.5;
    }
  }
}

/**
 * Text with image
 */
.text-with-image {
  p:last-child {
    margin-bottom: 0;
  }

  .col-lg-7 {
    p {
      margin-top: $spacer * 3;

      @include media-breakpoint-up(lg) {
        padding-left: $spacer * 4.5;
        margin-top: 0;
      }
    }

    &.order-lg-1 {
      p {
        @include media-breakpoint-up(lg) {
          padding-right: $spacer * 4.5;
          padding-left: 0;
        }
      }
    }
  }
}

.blockquote-text {
  align-self: center;
}

@include media-breakpoint-up(md) {
  .blockquote-with-image {
    .row {
      flex-wrap: nowrap;
    }
  }

  .blockquote-image {
    flex-grow: 1;
  }

  .blockquote-text {
    flex-shrink: 1;
  }

  .content-wrapper {
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
  }
}

@include media-breakpoint-down(md) {
  .blockquote-with-image {
    .row {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  .blockquote-image, .blockquote-text {
    width: 100%;
  }

  .blockquote-image {
    margin-bottom: $spacer * 1.5;
    padding: 0;
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    .img-fluid {
      max-width: 100vw;
    }
  }

  .blockquote-text {
    margin-bottom: $spacer * 2.25;
  }
}
