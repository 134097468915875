.highlight-text {
  align-self: center;

  h2 {
    font-size: $h4-font-size;
  }

  p {
    margin-bottom: $spacer * 2.25;
  }

  @include media-breakpoint-between(md, xl) {
    h2 {
      font-size: $font-size-base * 2;
      margin-bottom: $spacer * 2.25;
    }

    p {
      font-size: $font-size-base;
    }
  }

  @include media-breakpoint-up(xl) {
    h2 {
      font-size: $h2-font-size;
    }

    p {
      width: 80%;
      font-size: $h4-font-size;
      line-height: 1.5;
      margin-bottom: $spacer * 3;
    }
  }
}

@include media-breakpoint-up(md) {
  .highlight {
    .row {
      flex-wrap: nowrap;
    }
  }

  .highlight-image {
    flex-grow: 1;

    .img-fluid {
      width: 50vw;
      max-width: 864px; // image to viewport border until 1728px viewport width
    }
  }

  .highlight-text {
    flex-shrink: 1;
  }

  .content-wrapper {
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
  }
}

@include media-breakpoint-down(md) {
  .highlight {
    .row {
      display: block;
    }
  }

  .highlight-image, .highlight-text {
    width: 100%;
  }

  .highlight-image {
    margin-bottom: $spacer * 3;
    padding: 0;
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    .img-fluid {
      max-width: 100vw;
    }
  }

  .highlight-text {
    margin-bottom: $spacer * 5;
  }
}

@include media-breakpoint-down(sm) {
  .highlight-text {
    padding-left: $spacer * 1.25;
    padding-right: $spacer * 1.25;
  }
}
