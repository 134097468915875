.offcanvas-header {
  border-bottom: 1px solid $black;

  .navbar-brand {
    svg {
      g {
        fill: $nero;
      }
    }
  }

  .btn-download {
    &:hover {
      color: $gray;
    }

    &:focus-visible {
      color: $black;
    }
  }

  .btn-close {
    padding: $btn-close-padding-y $btn-close-padding-x;
    margin: 0;
  }
}

.offcanvas-body {
  padding-top: $spacer * 3;

  @include media-breakpoint-up(lg) {
    padding-top: $spacer * 6;
  }

  @include media-breakpoint-up(md) {
    padding-top: $spacer * 4.5;
  }

  .nav-item {
    .nav-link {
      padding: $nav-link-padding-y $nav-link-padding-x;
      margin-bottom: $spacer * 2.5;
      line-height: normal;
      border: 2px solid transparent;
      display: inline-flex;

      &:hover {
        text-decoration: underline;
      }

      &:focus-visible {
        border: 2px solid $gray;
        outline: 0;
        border-radius: 4px;
      }

      @include media-breakpoint-down(md) {
        font-size: $font-size-base;
        margin-bottom: $spacer * 1.75;
      }
    }
  }

  .right-part {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid $black;
      padding-left: $spacer * 2.75;
      justify-content: space-between;
    }

    .list-unstyled {
      @include media-breakpoint-down(lg) {
        margin-top: $spacer * 5.5;
        margin-bottom: $spacer;
      }

      @include media-breakpoint-down(md) {
        margin-top: $spacer * 3;
      }

      li {
        margin-bottom: $spacer;

        a {
          text-decoration: none;
          padding: $spacer * 0.25;
          border: 2px solid transparent;
          line-height: normal;
          font-size: $font-size-base * 1.3333;

          @include media-breakpoint-down(md) {
            font-size: $font-size-base;
          }

          &:hover {
            text-decoration: underline;
          }

          &:focus-visible {
            border: 2px solid $gray;
            outline: 0;
            border-radius: 4px;
          }
        }
      }
    }

    .icon-link {
      svg {
        @include media-breakpoint-down(md) {
          height: $font-size-base;
        }
      }
    }

    .social-links {
      @include media-breakpoint-up(lg) {
        margin-left: calc(($spacer * 0.75 - 2px) * -1);
      }

      @include media-breakpoint-down(md) {
        margin-left: calc(($spacer * 0.75 - 2px) * -1);
      }
    }
  }
}
