footer {
  display: flex;
  padding-top: $spacer * 3.875;
  padding-bottom: $spacer * 5;

  @include media-breakpoint-up(lg) {
    padding-top: $spacer * 14.5;
    padding-bottom: $spacer * 5.125;
  }

  @include media-breakpoint-up(md) {
    padding-top: $spacer * 3.875;
    padding-bottom: $spacer * 4.5;
  }

  @include media-breakpoint-down(sm) {
    .container {
      padding-left: $spacer * 1.25;
      padding-right: $spacer * 1.25;
    }
  }

  a {
    border: 2px solid transparent;

    &:focus-visible {
      border: 2px solid $gray;
      outline: 0;
      border-radius: 4px;
    }
  }

  .logo {
    display: inline-block;
    padding: $spacer;
    margin-left: calc(($spacer + 2px) * -1);

    @include media-breakpoint-down(md) {
      margin-bottom: $spacer * 1.875;
    }

    svg {
      max-width: 191px;
      g {
        fill: $nero;
      }
    }
  }

  .footer-navbar {
    padding-left: 0;
    margin-bottom: 0;

    @include media-breakpoint-only(md) {
      margin-top: $spacer * 4.625;
      margin-bottom: $spacer * 4.625;
    }

    @include media-breakpoint-up(md) {
      margin-left: calc(($spacer * 0.25 + 2px) * -1);
    }

    .list-inline-item {

      &:not(:last-child) {
        margin-right: $spacer * 2.25;
      }

      @include media-breakpoint-down(md) {
        display: block;
        margin-right: 0;

        &:not(:last-child) {
          margin-bottom: $spacer * 2.25;
        }
      }

      a {
        text-decoration: none;
        padding: $spacer * 0.25;

        @include media-breakpoint-down(md) {
          margin-left: calc(($spacer * 0.25 + 2px) * -1);
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-links {
    @include media-breakpoint-down(md) {
      margin-top: $spacer * 2.125;
      margin-bottom: $spacer * 1.375;
      margin-left: calc(($spacer * 0.75 + 2px) * -1);
    }
  }

  .footer-navbar-text {
    font-size: $font-size-base * 0.6667;
    display: block;

    @include media-breakpoint-up(lg) {
      margin-top: $spacer * 3.375;
    }
  }
}
