@function calculate-aspect-height($width, $aspect-width, $aspect-height) {
  @return calc($width / $aspect-width) * $aspect-height;
}

.categories-container {
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.panel {
  flex: 0.5;
  margin-left: 1px;
  position: relative;
  transition: flex 0.7s cubic-bezier(.8, 0, .2, 1);
  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: row;
  }

  &.active {
    flex: 1;
    .card-img-top {
      @include media-breakpoint-up(lg) {
        &:before {
          background-color: transparent;
        }
      }
    }
  }

  .card-img-top {
    position: relative;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(lg) {
      height: calculate-aspect-height(35.2vw, 3, 4);
      max-width: 35.2vw;
    }
    @include media-breakpoint-up(lg) {
      height: calculate-aspect-height(28.5vw, 4, 4);
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        transition: background-color 0.7s cubic-bezier(.8, 0, .2, 1);
        z-index: 1;
      }
    }
  }

  .card-body {
    display: flex;

    @include media-breakpoint-up(lg) {
      padding: $spacer * 6 0;
      min-height: 392px;
    }

    @include media-breakpoint-down(lg) {
      padding: 0 $spacer * 1.125;
      min-height: calculate-aspect-height(28.5vw, 3, 4);
      align-items: center;
    }

    .btn-read-more {

      @include media-breakpoint-up(lg) {
        text-align: center;
        flex: 1 1 auto;
        flex-flow: column;
        justify-content: flex-end;
        gap: 0;
        hyphens: auto;

        span {
          margin-bottom: $spacer * 3;
        }
      }

      @include media-breakpoint-down(lg) {
        font-size: $font-size-base * 0.8889;
        align-items: flex-start;
        gap: 0;

        span {
          margin-bottom: $spacer * 1.5;
        }
      }
    }
  }
}
