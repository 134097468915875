.card-title {
  @include font-size($font-size-base * 1.3333);
  @include margin-bottom($spacer * 1.125);
}

.card-text {
  opacity: 0.72;

  &:last-child {
    @include margin-bottom($spacer * 1.125);
  }
}

.card-footer {
  padding-top: 0;
}
