.social-links {
  margin-bottom: 0;

  .list-inline-item {
    margin-right: 0;
  }

  a {
    display: block;
    border: 2px solid transparent;

    &:focus-visible {
      border: 2px solid $gray;
      outline: 0;
      border-radius: 4px;
    }

    &:hover {
      path {
        fill: $gray;
      }
    }
  }
}
