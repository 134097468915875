@function calculate-aspect-height($width, $aspect-width, $aspect-height) {
  @return calc($width / $aspect-width) * $aspect-height;
}

header {
  position: relative;
  height: 100vh;

  @include media-breakpoint-up(lg) {
    height: calculate-aspect-height(100vw, 16, 9);
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.background-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  .header-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .header-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.skiplinks {
  margin: $spacer 0;
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  width: 100%;

  a {
    text-decoration: none;
    border: 2px solid transparent;
    padding: $spacer * 0.25;

    &:focus-visible {
      border: 2px solid $gray;
      outline: 0;
      border-radius: 4px;
    }
  }
}
