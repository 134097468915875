.btn-close {
  border: 2px solid transparent;

  &:hover {
    background-image: escape-svg($btn-close-hover-bg);
  }

  &:focus-visible {
    border: 2px solid $gray;
    outline: 0;
    border-radius: 4px;
    box-shadow: none;
  }
}
